import * as tslib_1 from "tslib";
import { Platform } from '@ionic/angular';
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { NotificationService } from "./notification.service";
export class WhatsappService {
    constructor(notificationService, platform) {
        this.notificationService = notificationService;
        this.platform = platform;
        this.isConnected = false;
        this.signalRConnectionSubject = new BehaviorSubject(null);
        this.signalRConnection$ = this.signalRConnectionSubject.asObservable();
        this.receivedMessageSubject = new BehaviorSubject(null);
        this.receivedMessage$ = this.receivedMessageSubject.asObservable();
        this.signalRConnectionCloseRequested = false;
    }
    setSignalRConnection(signalRConnection) {
        this.signalRConnectionSubject.next(signalRConnection);
    }
    setReceivedMessage(receivedMessage) {
        this.receivedMessageSubject.next(receivedMessage);
    }
    startConnection(userEmail) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                console.log('startConnection', userEmail);
                this.signalRConnectionCloseRequested = false;
                this.signalRConnection.start().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (this.signalRConnection) {
                        yield this.signalRConnection.invoke('ConnectUser', userEmail);
                        this.isConnected = true;
                    }
                }), err => {
                    console.error('start signalRConnection rejected', err);
                }).catch(err => {
                    console.error('start signalRConnection error', err);
                });
            }
            catch (err) {
                console.error('startConnection error', err);
                setTimeout(() => {
                    this.startConnection(userEmail);
                }, 5000);
            }
        });
    }
    ;
    subscribe(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.platform.ready();
            this.signalRConnectionSubscription = this.signalRConnection$.subscribe((con) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (con !== null && !this.signalRConnection) {
                    this.signalRConnection = con;
                    yield this.startConnection(user.email);
                }
            }));
            let connection = new signalR.HubConnectionBuilder()
                .withUrl(`${environment.baseFixUrl}chatHub`, { transport: signalR.HttpTransportType.WebSockets, skipNegotiation: true })
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            connection.onclose((error) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!this.signalRConnectionCloseRequested) {
                    yield this.startConnection(user.email);
                }
                if (error) {
                    console.error("error in connection.onclose", error);
                }
            }));
            this.setSignalRConnection(connection);
        });
    }
    unsubscribe(user) {
        if (this.signalRConnection) {
            if (this.signalRConnection.state == signalR.HubConnectionState.Connected) {
                this.signalRConnection.invoke('DisconnectUser', user.email)
                    .finally(() => {
                    if (this.signalRConnection) {
                        this.signalRConnectionCloseRequested = true;
                        if (this.isConnected) {
                            this.signalRConnection.stop().then(_ => {
                                this.signalRConnection = null;
                                this.setSignalRConnection(null);
                                this.isConnected = false;
                            });
                        }
                    }
                });
            }
            else {
                this.signalRConnection = null;
                this.setSignalRConnection(null);
            }
            if (this.signalRConnectionSubscription) {
                this.signalRConnectionSubscription.unsubscribe();
            }
            this.setReceivedMessage(null);
            this.receivedMessage = null;
            if (this.receivedMessageSubscription) {
                this.receivedMessageSubscription.unsubscribe();
            }
        }
    }
}
